import { useTranslation } from "react-i18next";

const Whatsapp = ({ event, dateLabel }) => {
  const { t } = useTranslation()

  const payload = {
    date: dateLabel,
    day: event.day,
    time: event.time,
    range: event.range,
    whatsapp: event.whatsapp,
    consumer: event.consumer.label,
    phone: event.consumer.phone,
    email: event.consumer.email,
    identification: event.consumer.identification,
    resource: event.resource.label,
    service: event.service.label,
    address: event?.location?.address
  }

  let text = `Hola, acabo de solicitar un turno por medio de Wiri. Te paso los detalles asi me lo confirmas\n\n`
  text += `*Turno*\n`
  text += `${payload.date}\n`
  text += `${payload.service}. ${payload.resource}\n`
  text += `${payload.address}`
  text += `\n\n*Mis datos*\n`
  text += [payload.consumer, payload.identification, payload.phone, payload.email].filter(Boolean).join(`\n`)

  payload.text = encodeURIComponent(
    t(`event_flow_${event.flow}_text`, text, payload)
  )

  return (
    <div className="h-screen flex flex-col justify-center">
      <div
        className="mx-3 rounded-lg"
        dangerouslySetInnerHTML={{
          __html: t(
            `event_flow_${event.flow}_html`,
            `<div class="bg-yellow-100 border border-yellow-500 rounded-md p-4 text-yellow-700">Este centro requiere que te pongas en contacto por WhatsApp para terminar de confirmar el turno. Tenemos que mandarle un mensaje por WhatsApp:</div>
            <div class="flex flex-col gap-3 bg-white text-secondary/75 p-3 border-4 border-gray-600 my-3 rounded">
              <div>Hola, acabo de solicitar un turno por medio de Wiri. Te paso los detalles para confirmarlo</div>
              <div>
                <div><strong>Turno</strong></div>
                <div>${payload.service}. ${payload.resource}</div>
                <div>${payload.date}</div>
                <div>${payload.address}</div>
              </div>
              <div>
                <div><strong>Mis datos</strong></div>
                ${[payload.consumer, payload.identification, payload.phone, payload.email].filter(Boolean).map(i => `<div>${i}</div>`).join('')}
              </div>
            </div>
            <div class="mt-4 flex justify-center">
              <a href="https://wa.me/${payload.whatsapp}?text=${payload.text}" target="whatsapp" class="bg-primary p-3 text-white rounded-lg" rel="noopener noreferrer">Enviar Mensaje</a>
            </div>
            `,
            payload
          )
        }}
      />
    </div>
  )
}

export default Whatsapp
